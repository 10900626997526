const InfoTag = ({ count, percent }) => {
  let text = "";
  let color = "";

  if (typeof count === "number") {
    if (count === 0) {
      text = "No Data";
      color = "#808080";
    } else if (typeof percent === "number") {
      if (percent > 75) {
        text = "Nearly Full";
        color = "#d96464";
      } else if (percent > 60) {
        text = "Limited Space";
        color = "#ffa100";
      } else {
        text = "Space Available";
        color = "#4caf50";
      }
    }
  }

  return text ? (
    <div
      className="space-info-col"
      style={{
        border: `1px solid ${color}`,
      }}
    >
      <div style={{ color }}>{text}</div>
    </div>
  ) : (
    "--"
  );
};

export default InfoTag;
