import moment from "moment";

const userAgent = navigator?.userAgent;
const IOS_PREFERRED_APP_KEY = "magnus:wccc:ios:map:app";

export const MAP_APPS = {
  GOOGLE_MAPS: "GOOGLE_MAPS",
  APPLE_MAPS: "APPLE_MAPS",
};

export const isIOS =
  userAgent?.includes("iPhone") ||
  userAgent?.includes("Macintel") ||
  userAgent?.includes("iPad");

export const getIOSPreferredApp = () =>
  localStorage.getItem(IOS_PREFERRED_APP_KEY);

export const setIOSPreferredApp = (appType) => {
  localStorage.setItem(IOS_PREFERRED_APP_KEY, appType);
};

export const getMapLink = (lat, lng, mapApp) => {
  let mapLink = "";
  const googleMapsLink = `https://www.google.com/maps/dir/My+Location/${lat},${lng}`;
  const appleMapsLink = `http://maps.apple.com/?saddr=My+Location&daddr=${lat},${lng}`;

  if (mapApp) {
    if (mapApp === MAP_APPS.GOOGLE_MAPS) {
      return googleMapsLink;
    }
    if (appleMapsLink === MAP_APPS.APPLE_MAPS) {
      return googleMapsLink;
    }
  }
  if (isIOS) {
    const preferredApp = getIOSPreferredApp();
    mapLink =
      preferredApp === MAP_APPS.GOOGLE_MAPS ? googleMapsLink : appleMapsLink;
  } else {
    mapLink = googleMapsLink;
  }
  return mapLink;
};

export const getLastUpdated = (utcTime) => {
  let lastUpdated = "";
  try {
    const currentUTC = moment(moment.utc().format("YYYY-MM-DD HH:mm:ss"));
    const givenUTC = moment(utcTime);
    lastUpdated = currentUTC.to(givenUTC);
  } catch (error) {
    //no-op
  }
  return lastUpdated;
};

export const getMaxDate = (date1, date2) => {
  let maxDate = "";
  try {
    if (date1 && date2) {
      const date1InMilliseconds = moment(date1).valueOf();
      const date2InMilliseconds = moment(date2).valueOf();
      maxDate = date1InMilliseconds > date2InMilliseconds ? date1 : date2;
    }
  } catch (error) {
    // no-op
  }
  return maxDate;
};

export const isUpdatedRecently = (utcTime) => {
  let isUpdatedWithin3days = false;
  try {
    if (utcTime) {
      const utcBefore3days = moment.utc().subtract(3, "days").valueOf();
      const givenUTC = moment(utcTime).valueOf();
      isUpdatedWithin3days = givenUTC > utcBefore3days;
    }
  } catch (error) {
    // no-op
  }
  return isUpdatedWithin3days;
};
