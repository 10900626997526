import { useState, useEffect, useCallback, useRef } from "react";
import SearchIcon from "../../assets/images/search.svg";
import ClearIcon from "../../assets/images/close-circle.svg";
import "./styles.css";

const SiteSearch = ({ sites, onSearchItemClick, onFocusOnMobileView }) => {
  const [input, setInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const inputRef = useRef();

  const onInputChange = useCallback((e) => {
    const value = e?.target?.value;
    setInput(value);
  }, []);

  const onClearClick = useCallback(() => {
    setInput("");
  }, []);

  const onDropDownItemClick = useCallback(
    (site) => {
      setInput("");
      if (onSearchItemClick && site) {
        onSearchItemClick(site);
      }
    },
    [onSearchItemClick]
  );

  useEffect(() => {
    const isDesktop = window.innerWidth >= 768;
    if (isDesktop && inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (input && input?.length && sites?.length) {
      const result = sites.filter((site) => {
        const lowerCasedSiteName = site?.Name?.toLowerCase?.();
        const lowerCasedInput = input.toLowerCase();
        return lowerCasedSiteName.includes(lowerCasedInput);
      });
      setSearchResult(result);
    } else {
      setSearchResult([]);
    }
  }, [input, sites]);

  return (
    <div className="search-container">
      <div className="row-1">
        <div className="search-icon-container">
          <img src={SearchIcon} alt="search" className="search-img" />
        </div>
        <div className="search-input-container">
          <input
            ref={inputRef}
            className="text-input"
            placeholder="Search..."
            value={input}
            onChange={onInputChange}
            onFocus={() => {
              if (onFocusOnMobileView) {
                onFocusOnMobileView();
              }
            }}
          />
        </div>
        {input?.length ? (
          <div className="clear-icon-container" onClick={onClearClick}>
            <img src={ClearIcon} alt="clear" className="clear-img" />
          </div>
        ) : null}
      </div>
      <div className="row-2">
        {searchResult?.length ? (
          <div className="drop-down-container">
            {searchResult.map((site) => (
              <div
                key={`search-item-${site?.Name}`}
                className="search-drop-down-item"
                onClick={() => {
                  onDropDownItemClick(site);
                }}
              >
                {site?.Name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SiteSearch;
