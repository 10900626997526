import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import jwt_decode from "jwt-decode";

import {
  getIOSPreferredApp,
  getLastUpdated,
  getMapLink,
  getMaxDate,
  isIOS,
  isUpdatedRecently,
} from "./utils";
import styles from "./map.styles.json";
import { getSitesGridListAPI, signInUsingCredentialsAPI } from "./api";
import sites from "./sites.json";
import magnusLogo from "./assets/images/brand.png";
import loader from "./assets/images/loader.svg";
import GreenIcon from "./assets/images/green_marker.svg";
import RedIcon from "./assets/images/red_marker.svg";
import YellowIcon from "./assets/images/yellow_marker.svg";
import GreyIcon from "./assets/images/grey_marker.svg";
import UpArrow from "./assets/images/up-arrow.svg";
import DirectionIcon from "./assets/images/direction.png";
import InfoTag from "./components/InfoTag";
import SiteSearch from "./components/SiteSearch";
import IOSMapAppsModal from "./components/IOSMapAppsModal";
import "./App.css";

const defaultCenter = {
  lat: 52.288329280606334,
  lng: -7.315127369008887,
};

const DUNMORE_EAST_STRAND_CARPARK_SITE_ID = 99999;

function App() {
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [isDataFetchedFromAPI, setIsDataFetchedFromAPI] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [selectedSiteID, setSelectedSiteID] = useState(null);
  const [error, setError] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [mapData, setMapData] = useState([]);
  const [windowInnerHeight, setWindowInnerHeight] = useState(
    window.innerHeight
  );

  const googleMapRef = useRef();
  const listItemRefs = useRef({});
  const iosMapAppsModalRef = useRef();

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 425);
    setWindowInnerHeight(window.innerHeight);
  };

  const loadData = async () => {
    try {
      setError("");
      setIsDataFetchedFromAPI(false);
      const data = [];
      let token = localStorage.getItem("magnus:map:auth:token");

      let isSignInRequired = false;

      if (!token) {
        isSignInRequired = true;
      } else {
        const decodedInfo = jwt_decode(token);
        const exp = decodedInfo?.exp;
        isSignInRequired = Date.now() >= exp * 1000;
      }

      if (isSignInRequired) {
        await signInUsingCredentialsAPI();
      }

      const response = await getSitesGridListAPI();
      const gridListData = response.data;

      sites.forEach((site) => {
        const siteData = {
          SiteID: site?.SiteID,
          Name: "",
          Lat: "",
          Lng: "",
          maxPercent: 0,
          lastUpdated: "",
          Bins: {},
          totalBins: 0,
        };
        const Bins = {};

        if (site?.bins?.length) {
          site.bins.forEach((bin) => {
            const type = bin?.type;
            Bins[type] = {
              noOfMonitorInstalled: 0,
              cumulativePercent: 0,
              avgPercent: 0,
            };
          });

          site.bins.forEach((bin) => {
            const type = bin?.type;

            if (type && bin?.id) {
              const gridInfoForBinID = gridListData.find(
                (item) => bin.id === item?.tank_name
              );

              if (gridInfoForBinID) {
                const readingDate = gridInfoForBinID?.reading_date;
                const isRecentReading = isUpdatedRecently(readingDate);

                if (readingDate && isRecentReading) {
                  siteData.totalBins += 1;
                  Bins[type].noOfMonitorInstalled += 1;

                  /**
                   * Bin level is calculated differently for Dunmore East - Strand Car Park Site.
                   * This Site consists of 6 bins (Brown - 2, Clear - 2, Green - 2).
                   * Out of 6 only 3 bins are placed in the location. (Active Bins) (Brown - 1, Clear - 1, Green - 1).
                   * Rest 3 bins are placed in warehouse/factory. (Idle Bins). (The Bins will be empty).
                   * Once the bins from the location fulled, it will be replaced by the Idle bins. (Now Idle becomes Active and vice versa)
                   * The level info should be used from only active bins.
                   * To fulfill the requirement, max percent is stored as avg percent for the bin in the following snippet.
                   */
                  if (siteData.SiteID === DUNMORE_EAST_STRAND_CARPARK_SITE_ID) {
                    Bins[type].avgPercent = Math.max(
                      Bins[type].avgPercent,
                      gridInfoForBinID?.percent
                    );
                  } else {
                    Bins[type].cumulativePercent += gridInfoForBinID?.percent;
                    Bins[type].avgPercent =
                      Bins[type].cumulativePercent /
                      Bins[type].noOfMonitorInstalled;
                  }

                  if (siteData.lastUpdated) {
                    siteData.lastUpdated = getMaxDate(
                      siteData.lastUpdated,
                      readingDate
                    );
                  } else {
                    siteData.lastUpdated = readingDate;
                  }
                }

                if (!siteData.Name && gridInfoForBinID?.address) {
                  siteData.Name = gridInfoForBinID.address;
                }

                if (!siteData?.Lat || !siteData?.Lng) {
                  if (gridInfoForBinID?.lat && gridInfoForBinID?.long) {
                    siteData.Lat = Number(gridInfoForBinID.lat);
                    siteData.Lng = Number(gridInfoForBinID.long);
                  }
                }
              }
            }
          });
        }

        Object.entries(Bins).forEach((entry) => {
          if (entry[1]?.avgPercent > siteData.maxPercent) {
            siteData.maxPercent = entry[1]?.avgPercent;
          }
        });

        // Bins sorted in Alphabetical order
        siteData.Bins = Object.keys(Bins)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = Bins[key];
            return accumulator;
          }, {});

        data.push(siteData);
      });

      data.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

      setMapData(data);
      setIsDataFetchedFromAPI(true);
    } catch (error) {
      console.log({ error });
      setError("Something went wrong. Please try agin later.");
    }
  };

  const setCenterAndZoomToLocation = (lat, lng) => {
    googleMapRef.current?.state?.map?.setCenter?.({
      lat,
      lng,
    });
    if (!isMobileView) {
      googleMapRef.current?.state?.map?.setZoom?.(12);
    }
  };

  const scrollToSiteID = (siteID) => {
    listItemRefs.current?.[siteID].scrollIntoView({
      block: "start",
      inline: "start",
    });
  };

  const onDirectionButtonClick = (lat, lng) => {
    let mapsLink = getMapLink(lat, lng);

    if (isIOS) {
      const preferredApp = getIOSPreferredApp();
      if (!preferredApp && iosMapAppsModalRef.current?.show) {
        mapsLink = "";
        iosMapAppsModalRef.current.show(lat, lng);
      }
    }
    if (mapsLink) {
      window?.open?.(mapsLink, "_blank");
    }
  };

  const renderCard = (item) => {
    const isSelected = item?.SiteID === selectedSiteID;

    return (
      <div
        className={`card-container${
          isSelected ? " selected-card-container" : ""
        }`}
        key={item?.SiteID}
        ref={(ref) => {
          listItemRefs.current[item?.SiteID] = ref;
        }}
        onClick={() => {
          if (isMobileView) {
            setShowMobileDrawer(false);
          }
          setSelectedSiteID(item?.SiteID);
          setCenterAndZoomToLocation(item?.Lat, item?.Lng);
          if (isMobileView) {
            scrollToSiteID(item?.SiteID);
          }
        }}
      >
        <div className="card-heading-container">
          <div>
            <div className="heading">{item?.Name}</div>
          </div>
          <div className="direction-img-container">
            <button
              onClick={(e) => {
                e?.stopPropagation?.();
                onDirectionButtonClick(item?.Lat, item?.Lng);
              }}
            >
              <img src={DirectionIcon} alt="direction" />
            </button>
          </div>
        </div>

        {Object.entries(item?.Bins).map((entry, index) => {
          const binType = ["Green", "Brown", "Clear"].includes(entry[0])
            ? `${entry[0]} Glass`
            : entry[0];

          return (
            <div className="card-row" key={`${item?.Name}-${index}`}>
              <div className="bin-type-col">{binType}</div>
              <InfoTag
                count={entry[1]?.noOfMonitorInstalled}
                percent={entry[1]?.avgPercent}
              />
            </div>
          );
        })}
        <div className="last-updated">
          Last updated:{" "}
          {item?.lastUpdated
            ? getLastUpdated(item?.lastUpdated, item?.SiteID)
            : "--"}
        </div>
      </div>
    );
  };

  const renderMapItems = () => {
    if (!mapData.length) {
      return <div>Data Not Available</div>;
    }

    return mapData.map((item) => renderCard(item));
  };

  const onSearchItemClick = (site) => {
    if (isMobileView) {
      setShowMobileDrawer(false);
    }
    setSelectedSiteID(site?.SiteID);
    setCenterAndZoomToLocation(site?.Lat, site?.Lng);
    scrollToSiteID(site?.SiteID);
  };

  const requestLocationPermission = () => {
    const isMobileOrTab = window.innerWidth <= 768;
    if (isMobileOrTab && navigator?.geolocation?.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            typeof position?.coords?.latitude === "number" &&
            typeof position?.coords?.longitude === "number"
          ) {
            setCenterAndZoomToLocation(
              position.coords.latitude,
              position.coords.longitude
            );
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    }
  };

  const onInputFocusOnMobileView = useCallback(() => {
    setTimeout(() => {
      setShowMobileDrawer(true);
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  useEffect(() => {
    setZoom(window.innerWidth <= 425 ? 9 : 10);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    loadData();
    return () => {
      setSelectedSiteID(null);
    };
  }, []);

  useEffect(() => {
    setIsAppReady(isDataFetchedFromAPI && isMapLoaded);
    if (isDataFetchedFromAPI && isMapLoaded) {
      requestLocationPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFetchedFromAPI, isMapLoaded]);

  if (!isAppReady || error) {
    return (
      <div className="loading-container">
        <div className="loading-content-container">
          <div className="magnus-logo-container">
            <img src={magnusLogo} alt="magnus-logo" />
          </div>
          <div>
            {error ? (
              <div className="error-message">{error}</div>
            ) : (
              <img src={loader} alt="loader" className="svg-loader-img" />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="map-dashboard-wrapper"
        style={{ height: isMobileView ? windowInnerHeight : "100vh" }}
      >
        <div
          className="map-container"
          style={{
            height: isMobileView
              ? showMobileDrawer
                ? windowInnerHeight * 0.35
                : windowInnerHeight * 0.65
              : "100%",
          }}
        >
          <GoogleMap
            id="google-maps"
            ref={googleMapRef}
            center={defaultCenter}
            zoom={zoom}
            mapContainerStyle={{ height: "100%" }}
            options={{
              styles,
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              rotateControl: false,
              zoomControl: true,
              keyboardShortcuts: !isMobileView,
              backgroundColor: "#000000",
              gestureHandling: "greedy",
            }}
          >
            {mapData.map((item) => {
              let icon = GreenIcon;
              if (item?.totalBins !== 0) {
                if (item?.maxPercent > 75) {
                  icon = RedIcon;
                } else if (item?.maxPercent > 60) {
                  icon = YellowIcon;
                } else {
                  icon = GreenIcon;
                }
              } else {
                icon = GreyIcon;
              }

              return (
                <Marker
                  key={item?.SiteID}
                  position={{ lat: item?.Lat, lng: item?.Lng }}
                  icon={{
                    url: icon,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  onClick={() => {
                    scrollToSiteID(item?.SiteID);
                    setSelectedSiteID(item?.SiteID);
                    setCenterAndZoomToLocation(item?.Lat, item?.Lng);
                  }}
                >
                  {item?.SiteID === selectedSiteID ? (
                    <InfoWindow
                      onCloseClick={() => {
                        setSelectedSiteID(null);
                      }}
                    >
                      <span>{item.Name}</span>
                    </InfoWindow>
                  ) : null}
                </Marker>
              );
            })}
          </GoogleMap>
        </div>
        {isMobileView ? (
          <div
            className="mobile-view-drawer"
            style={{
              bottom: 0,
              height: showMobileDrawer
              ? windowInnerHeight * 0.65
              : windowInnerHeight * 0.35,
            }}
          >
            <div>
              <div
                className="drawer-button-container"
                onClick={() => {
                  setShowMobileDrawer((prevState) => !prevState);
                }}
              >
                <img
                  src={UpArrow}
                  alt="handler"
                  className={showMobileDrawer ? "arrow-rotation" : ""}
                />
              </div>
              <div className="mobile-search-container">
                <SiteSearch
                  sites={mapData}
                  onSearchItemClick={onSearchItemClick}
                  onFocusOnMobileView={onInputFocusOnMobileView}
                />
              </div>
            </div>
            <div className="map-list-container mobile-map-list-container">
              {renderMapItems()}
            </div>
          </div>
        ) : (
          <div className="desktop-list-wrapper">
            <div className="desktop-search-container">
              <SiteSearch
                sites={mapData}
                onSearchItemClick={onSearchItemClick}
              />
            </div>
            <div className="map-list-container desktop-map-list-container">
              {renderMapItems()}
            </div>
          </div>
        )}
      </div>
      {isIOS ? <IOSMapAppsModal ref={iosMapAppsModalRef} /> : null}
    </>
  );
}

export default App;
