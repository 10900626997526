import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA4 from "react-ga4";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID
) {
  ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA4.send("pageview");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
