const baseURL = process.env.REACT_APP_API_URL;

const isDev = process.env.NODE_ENV === "development";

export const signInUsingCredentialsAPI = () =>
  new Promise((resolve, reject) => {
    const request = `${baseURL}/api/signin`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: process.env.REACT_APP_USER_EMAIL,
        password: process.env.REACT_APP_USER_PASSWORD,
      }),
    };
    fetch(request, options)
      .then((response) => response.json())
      .then((response) => {
        localStorage.setItem("magnus:map:auth:token", response?.token);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getSitesGridListAPI = () =>
  new Promise((resolve, reject) => {
    const request = `${baseURL}/api/tanks/gridList?skip=0&take=150&requireTotalCount=true&sort=[{"selector":"percent","desc":true}]&filter=[["service_type","=","1"]]`;
    const token = localStorage.getItem("magnus:map:auth:token");
    if (!token) {
      reject();
    } else {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      fetch(request, options)
        .then((response) => response.json())
        .then((response) => {
          if (isDev) {
            console.log({ response });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
