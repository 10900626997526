import {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import { getMapLink, MAP_APPS, setIOSPreferredApp } from "../../utils";
import GoogleMapsIcon from "../../assets/images/google-maps.svg";
import AppleMapsIcon from "../../assets/images/apple-maps.png";
import CloseIcon from "../../assets/images/close-circle.svg";
import "./styles.css";

const DEFAULT_COORDS = {
  lat: "",
  lng: "",
};

const IOSMapAppsModal = forwardRef((_, ref) => {
  const [rememberAppPreference, setRememberAppPreference] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const coordsRef = useRef(DEFAULT_COORDS);

  const closeModal = useCallback(() => {
    coordsRef.current = DEFAULT_COORDS;
    setShowModal(false);
  }, []);

  const onMapAppClick = useCallback(
    (type) => {
      if (rememberAppPreference) {
        setIOSPreferredApp(type);
      }
      window?.open?.(
        getMapLink(coordsRef.current?.lat, coordsRef.current?.lng, type),
        "_blank"
      );
      closeModal();
    },
    [closeModal, rememberAppPreference]
  );

  const onCheckboxChange = useCallback((event) => {
    setRememberAppPreference(!!event?.target?.checked);
  }, []);

  const stopPropagationOnClick = useCallback((event) => {
    event?.stopPropagation?.();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      show: (lat, lng) => {
        coordsRef.current = {
          lat,
          lng,
        };
        setShowModal(true);
      },
      hide: () => {
        coordsRef.current = DEFAULT_COORDS;
        setShowModal(false);
      },
    }),
    []
  );

  if (!showModal) {
    return null;
  }

  return (
    <div className="map-apps-modal__container" onClick={closeModal}>
      <div className="map-apps-modal__content" onClick={stopPropagationOnClick}>
        <div className="map-apps-modal__header">
          <h2 className="map-apps-modal__header-title">Open with</h2>
          <button
            className="map-apps-modal__header-close-icon"
            onClick={closeModal}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <div className="map-apps-modal__app-logos-container">
          <div className="map-apps-modal__app-logo">
            <button
              className="apple-maps-logo"
              onClick={() => {
                onMapAppClick(MAP_APPS.APPLE_MAPS);
              }}
            >
              <img src={AppleMapsIcon} alt="apple-maps" />
            </button>
            <center>
              <p className="map-app-name">Maps</p>
            </center>
          </div>
          <div className="map-apps-modal__app-logo">
            <button
              className="google-maps-logo"
              onClick={() => {
                onMapAppClick(MAP_APPS.GOOGLE_MAPS);
              }}
            >
              <img src={GoogleMapsIcon} alt="google-maps" />
            </button>
            <center>
              <p className="map-app-name">Google Maps</p>
            </center>
          </div>
        </div>
        <div className="map-apps-modal__preference-container">
          <input
            type="checkbox"
            id="app-preference"
            checked={rememberAppPreference}
            onChange={onCheckboxChange}
          />
          <label htmlFor="app-preference">Don't ask again</label>
        </div>
      </div>
    </div>
  );
});

export default IOSMapAppsModal;
